<template>
    <section id="contacts">
        <div class="container template contacts">
            <div class="row">
                <div class="col-md-5 col">
                    <h2>
                        Контакты
                    </h2>
                    <div class="description" style="margin-top: 30px">
                        Связаться с нами вы можете по следующим контактам:
                    </div>

                    <div class="office">
                        <h4>
                            <div class="line"></div>
                            <span>
                                    Алматы <span class="sky">ОФИС</span>
                                </span>
                        </h4>

                        <div class="info">
                            <div class="icon">
                                <img src="@/assets/images/location.svg">
                            </div>
                            <a href='#'>ул. Желтоксан, 115</a>
                        </div>

                        <div class="info">
                            <div class="icon">
                                <img src="@/assets/images/phone.svg">
                            </div>
                            <a href="#">+7 747 677 32 52</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

    export default {
        data: ()=>({

        })
    }
</script>